import React from 'react'
import { graphql } from 'gatsby'
import BlockContent from '../components/block-content'
import Container from '../components/Container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Button from '../components/Button'
import { Flex, Box } from 'rebass'
import PageHeadline from '../components/PageHeadline'

export const query = graphql`
  query SchwerpunktePageQuery {
    page: sanityPage(_id: { regex: "/(drafts.|)schwerpunkte/" }) {
      id
      _id
      title
      _rawBody
      _rawSecondbody
      _rawThirdbody
      _rawFourthbody
    }
  }
`

const SchwerpunktePage = props => {
  const { data, errors } = props

  const page = data && data.page

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  if (!page) {
    throw new Error(
      'Missing "Schwerpunkte" page data. Open the studio at http://localhost:3333 and add "Schwerpunkte" page data and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO title={page.title} />
      <Container>
        <PageHeadline>{page.title}</PageHeadline>
        <Box mb="5"><BlockContent blocks={page._rawBody || []} /></Box>
        <Flex mb='5' flexWrap="wrap">
          <Box width={[1, 1 / 2]} pr={[0,5]}>
            <BlockContent blocks={page._rawSecondbody || []} />
          </Box>
          <Box width={[1, 1 / 2]}>
            <BlockContent blocks={page._rawThirdbody || []} />
          </Box>
        </Flex>
        <Box mb="5">
          <BlockContent blocks={page._rawFourthbody || []} />
        </Box>
        <Box mb="5">
          <Button to='/contact'>Kontakt aufnehmen</Button>
        </Box>
      </Container>
    </Layout>
  )
}

export default SchwerpunktePage
